<script>
export default {
  functional: true,
  props: {
    title: String
  },
  render(h, context) {
    return h('span', {class: 'header__title'}, context.children)
  }
}
</script>